<template>
  <section class="views">
    <headers select="9"></headers>
    <div class="views_content">
      <div class="views_banner">
        <img
          :src="require('@/assets/image/hodgepodge/h5banner_team.jpg')"
          alt=""
        />
      </div>

      <div class="viewsBoxTab">
        <div class="viewsTab" ref="timeSlide">
          <ul>
            <li
              v-for="(item, index) in tabColumns"
              :key="index"
              @click="tabBut(index)"
              :class="select == index ? 'select' : ''"
              :ref="`tab${index}Ref`"
            >
              <div>{{ item.name }}</div>
            </li>
          </ul>
        </div>
      </div>
      <div class="viewTabContent">
        <div class="ulsTab" ref="tabSlideTwo">
          <ul>
            <li
              v-for="(item, index) in tabColumns[select].dataTab"
              :key="index"
              :class="selectTwo == index ? 'select' : ''"
              @click="tabButTwo(index)"
              :ref="`tab${index}RefTwo`"
            >
              <div>{{ item.name }}</div>
              <div>（{{ item.fzhu }}）</div>
            </li>
          </ul>
        </div>

        <div class="box">
          <div v-if="select == 1" style="margin-bottom: 12px;color: #fff;font-size: 16px;font-weight:bold;">排名不分先後</div>
          <ul v-if="select == 2">
            <div class="wu">
              <img :src="require('@/assets/image/hodgepodge/wu.png')" alt="" />
              <span> 當前階段成績尚未揭曉哦 </span>
            </div>
          </ul>
          <ul v-if="select != 2">
            <li class="weiyitop">
              <div>序號</div>
              <div>學校/機構名</div>
            </li>

            <li class="lis" v-for="(item, index) in queryUserList" :key="index">
              <div>{{ item.competitionId }}</div>
              <div>{{ item.agencyName }}</div>
            </li>
          </ul>
          <!-- <ul v-if="select === 2">
            <li class="weiyitop">
              <div>獎項</div>
              <div>姓名</div>
              <div>電話</div>
            </li>
            <li class="lis" v-for="(item, index) in queryUserList" :key="index">
              <div>
                {{
                  item.ranking == 1
                    ? '金獎'
                    : item.ranking == 2
                    ? '銀獎'
                    : item.ranking == 3
                    ? '銅獎'
                    : item.ranking > 3
                    ? '優異獎'
                    : ''
                }}
              </div>
              <div>{{ item.studentName }}</div>
              <div>{{ item.phone }}</div>
            </li>
          </ul> -->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import headers from '@/components/header/h_header'
export default {
  name: 'views',
  data() {
    return {
      tabColumns: [
        {
          id: '0',
          name: '入圍總決賽名單',
          dataTab: [
            {
              name: '幼稚園組',
              fzhu: 'K1-K3'
            },
            {
              name: '小學初級組',
              fzhu: 'P1-P3'
            },
            {
              name: '小學高級組',
              fzhu: 'P4-P6'
            },
            {
              name: '中學組',
              fzhu: 'S1-S6'
            }
          ]
        },
        {
          id: '1',
          name: '團體賽優異獎',
          dataTab: [
            {
              name: '幼稚園組',
              fzhu: 'K1-K3'
            },
            {
              name: '小學初級組',
              fzhu: 'P1-P3'
            },
            {
              name: '小學高級組',
              fzhu: 'P4-P6'
            },
            {
              name: '中學組',
              fzhu: 'S1-S6'
            }
          ]
        },
        {
          id: '2',
          name: '總決賽成績',
          dataTab: [
            {
              name: '幼稚園組',
              fzhu: 'K1-K3'
            },
            {
              name: '小學初級組',
              fzhu: 'P1-P3'
            },
            {
              name: '小學高級組',
              fzhu: 'P4-P6'
            },
            {
              name: '中學組',
              fzhu: 'S1-S6'
            }
          ]
        }
      ],
      select: 0,
      selectTwo: 0,
      newShortActive: 0,
      queryUserList: [],
      finalists: [
        {
          group: 1,
          data: [
            {
              competitionId: 12,
              agencyName: '東華三院馬陳景霞幼稚園'
            },
            {
              competitionId: 14,
              agencyName: '嘉德麗中英文幼稚園'
            },
            {
              competitionId: 80,
              agencyName: '遵道幼稚園'
            },
            {
              competitionId: 5,
              agencyName: '綠茵英文（國際）幼稚園（將軍澳）'
            },
            {
              competitionId: 37,
              agencyName: '培僑國際幼稚園'
            },
            {
              competitionId: 29,
              agencyName: '東華三院馬陳家歡幼稚園'
            },
            {
              competitionId: 54,
              agencyName: '東華三院譚錦球伉儷幼稚園'
            },
            {
              competitionId: 79,
              agencyName: '聖公會荊冕堂葵涌幼稚園'
            },
          ]
        },
        {
          group: 2,
          data: [
            {
              competitionId: 27,
              agencyName: '中華基督教會基法小學（油塘）'
            },
            {
              competitionId: 25,
              agencyName: '慈航學校'
            },
            {
              competitionId: 17,
              agencyName: '鳳溪第一小學'
            },
            {
              competitionId: 9,
              agencyName: '中華基督教會元朗真光小學'
            },
            {
              competitionId: 74,
              agencyName: '安基司學校'
            },
            {
              competitionId: 77,
              agencyName: '路德會呂祥光小學'
            },
          ]
        },
        {
          group: 3,
          data: [
            {
              competitionId: 60,
              agencyName: '鳳溪創新小學'
            },
            {
              competitionId: 67,
              agencyName: '聖公會仁立紀念小學'
            },
            {
              competitionId: 30,
              agencyName: '沙田崇真學校'
            },
            {
              competitionId: 34,
              agencyName: '廣州暨大港澳子弟學校'
            },
            {
              competitionId: 75,
              agencyName: '深圳香港培僑書院龍華信義學校'
            },
            {
              competitionId: 39,
              agencyName: '香港南區官立小學'
            },
            {
              competitionId: 22,
              agencyName: '九龍城浸信會禧年（恩平）小學'
            },
          ]
        },
        {
          group: 4,
          data: [
            {
              competitionId: 84,
              agencyName: '中華基督教會燕京書院'
            },
            {
              competitionId: 19,
              agencyName: '聖公會林護紀念中學'
            },
            {
              competitionId: 50,
              agencyName: '鳳溪第一中學'
            },
            {
              competitionId: 56,
              agencyName: '仁濟醫院董之英紀念中學'
            },
            {
              competitionId: 65,
              agencyName: '東華三院郭一葦中學'
            },
            {
              competitionId: 52,
              agencyName: '鳳溪第一中學'
            },
            {
              competitionId: 33,
              agencyName: '中華基督教會何福堂書院'
            },
          ]
        },
      ]
    }
  },
  computed: {},
  components: {
    headers
  },
  watch: {
    select(val) {
      if (val) {
        console.log('發送改變', val)
      }
    }
  },
  created() {},
  mounted() {
    this.tabButTwo(0)
    this.$nextTick(() => {
      setTimeout(() => {
        this.scrollLeftTo(0)
      }, 1000)
    })
  },
  methods: {
    tabButTwo(e) {
      console.log(e, 'e')

      this.selectTwo = e
      this.queryUserList = []
      if (this.select == 0) {
        this.getScoreList()
      }else if (this.select == 1) {
        let groupId = this.selectTwo + 1
        this.finalists.forEach((item) => {
          if (item.group == groupId) {
            this.queryUserList = item.data
          }
        })
      }

      // if (this.select != 0) {
      //   this.getScoreList(0)
      // } else {
      //   this.queryUserList = []
      // let groupId = this.selectTwo + 1
      // this.finalists.forEach((item) => {
      //   if (item.group == groupId) {
      //     this.queryUserList = item.data
      //   } else {
      //     this.queryUserList = []
      //   }
      // })
      // }
      this.scrollLeftToTwo(e)
    },
    tabBut(e) {
      this.select = e
      this.queryUserList = []
      if (this.select == 0) {
        this.getScoreList()
        // } else {
        //   this.queryUserList = []
        //   let groupId = this.selectTwo + 1
        //   this.finalists.forEach((item) => {
        //     if (item.group == groupId) {
        //       this.queryUserList = item.data
        //     }
        //   })
      }else if (this.select == 1) {
        let groupId = this.selectTwo + 1
        this.finalists.forEach((item) => {
          if (item.group == groupId) {
            this.queryUserList = item.data
          }
        })
      }
      // 触发滑动方法
      this.scrollLeftTo(e)
    },
    // 滑动
    scrollLeftTo(name) {
      const ref = `tab${name}Ref`
      const nav = this.$refs.timeSlide
      console.log('额:', this.$refs.timeSlide.offsetWidth)
      const title = this.$refs[ref][0]
      
      // 计算位移偏差
      const to = title.offsetLeft - (nav.offsetWidth - title.offsetWidth) / 2
      nav.scrollLeft = to
    },
    // 滑动
    scrollLeftToTwo(name) {
      const ref = `tab${name}RefTwo`
      const nav = this.$refs.tabSlideTwo
      // console.log("额:", this.$refs.tabSlideTwo.offsetWidth);
      const title = this.$refs[ref][0]
      // 计算位移偏差
      const to = title.offsetLeft - (nav.offsetWidth - title.offsetWidth) / 2
      nav.scrollLeft = to
    },
    getScoreList() {
      this.$toast.loading({
        message: '正在查詢中...',
        duration: 0,
        forbidClick: true
      })
      let groupId = this.selectTwo + 1
      let scoreType = this.select + 1
      let params = '?groupId=' + groupId
      this.$axios
        .get(this.$api.promotionList + params)
        .then((response) => {
          this.$toast.clear()
          console.log(response, 'response');
          
          let arr = response.data.rows
          this.queryUserList = arr

          // 数据最多返回100条，超出请求2次
          if (response.data.total > 100 && pageNum === 1) {
            this.getScoreList(2)
          }
        })
        .catch((error) => {
          //请求失败
          this.$toast.fail('error')
          console.log(error)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.views {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: linear-gradient(225deg, #ff8310 0%, #fe840f 100%);
  .views_content::-webkit-scrollbar {
    display: none;
  }
  .views_content {
    width: 100%;
    height: calc(100% - 0.92rem);
    overflow: scroll;

    .views_banner {
      width: 100%;
      display: grid;

      img {
        width: 100%;
      }
    }

    .viewsTab::-webkit-scrollbar {
      display: none;
    }

    .viewsBoxTab {
      width: 100%;
    }

    .viewsTab {
      width: 100%;
      padding: 0.16rem 0;
      box-sizing: border-box;
      overflow-y: scroll;
      margin-top: -6%;

      ul {
        //width: 100%;
        height: 1.28rem;
        display: inline-flex;
        justify-content: center;
        align-items: center;

        li {
          width: 3.2rem;
          max-width: 50%;
          height: 100%;
          display: flex;
          align-content: center;
          align-items: center;
          flex-wrap: wrap;
          font-family: PingFang SC-Semibold, PingFang SC;
          line-height: 0.38rem;
          flex: 0 0 auto;
          background: #d34a00;
          cursor: pointer;

          div {
            width: 100%;
            font-size: 0.32rem;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 0.38px;
          }
        }

        .select {
          font-weight: 600;
          color: #903300;
          padding: 0.16rem 0;
          background: url('~@/assets/image/hodgepodge/tabbuttom.png') no-repeat;
          background-size: 100% 100%;

          div {
            font-size: 0.4rem !important;
            font-family: PingFang SC-Semibold, PingFang SC;
            font-weight: 600 !important;
            color: #903300;
            line-height: 0.47rem !important;
          }
        }
      }
    }

    .viewTabContent {
      width: 100%;

      .ulsTab::-webkit-scrollbar {
        display: none;
      }

      .ulsTab {
        width: 100%;
        padding: 0.32rem 0 0 0.32rem;
        box-sizing: border-box;
        overflow-y: scroll;

        ul {
          width: 100%;
          display: inline-flex;
          justify-content: flex-start;
          align-items: center;

          li {
            margin-right: 0.32rem;
            width: 2.4rem;
            height: 1.32rem;
            background: #ffffff;
            border: 0.04rem solid #ffffff;
            display: flex;
            align-content: center;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            font-family: PingFang SC-Semibold, PingFang SC;
            line-height: 0.38rem;
            flex: 0 0 auto;
            text-align: center;
            border-radius: 0.32rem;

            div {
              width: 100%;
              text-align: center;
            }

            div:nth-child(1) {
              font-size: 0.32rem;
              font-family: PingFang SC-Regular, PingFang SC;
              color: #666666;
              line-height: 0.38rem;
            }

            div:nth-child(2) {
              font-size: 0.26rem;
              font-family: PingFang SC-Regular, PingFang SC;
              color: #666666;
              line-height: 0.3rem;
              margin-top: 0.04rem;
            }
          }
          .select {
            background: #fdd472 !important;

            div:nth-child(1) {
              font-size: 0.32rem !important;
              font-family: PingFang SC-Semibold, PingFang SC;
              font-weight: 600;
              color: #903300 !important;
              line-height: 0.38rem;
            }

            div:nth-child(2) {
              font-size: 0.26rem !important;
              font-family: PingFang SC-Semibold, PingFang SC;
              font-weight: 600;
              color: #903300 !important;
              line-height: 0.3rem !important;
            }
          }
        }
      }

      .box {
        width: 100%;
        padding: 0.32rem;
        padding-top: 0.64rem;
        box-sizing: border-box;

        ul::-webkit-scrollbar {
          display: none;
        }

        ul {
          width: 100%;
          height: 70vh;
          border-radius: 0.16rem;
          background: #ffffff;
          border: 0.06rem solid #ffffff;
          box-sizing: border-box;
          overflow-x: hidden;
          position: relative;

          .wei {
            width: 100%;
            height: 0.92rem;
          }

          .weiyitop {
            width: 100%;
            height: 0.92rem;
            background: #ffb559;
            display: flex;
            position: sticky;
            left: 0;
            top: 0;

            div {
              // width: 33%;
              text-align: center;
              font-size: 0.28rem;
              font-family: PingFang SC-Semibold, PingFang SC;
              font-weight: 600;
              color: #a94800;
              line-height: 0.92rem;
            }
            div:nth-child(1) {
              width: 24%;
            }
            div:nth-child(2) {
              width: 76%;
              text-align: left;
            }
          }

          .lis {
            width: 100%;
            height: 0.92rem;
            margin: auto;
            background: #ffffff;
            display: flex;
            border-bottom: 0.01rem;
            div:nth-child(1) {
              width: 24%;
              text-align: center;
            }
            div:nth-child(2) {
              width: 76%;
              text-align: left;
            }
            div {
              font-size: 0.28rem;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
              line-height: 0.92rem;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }
          }
        }
        .wu {
          width: 100%;
          // height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
          align-content: center;
          padding-top: 1rem;

          img {
            width: 4.36rem;
            margin-bottom: 0.56rem;
          }

          span {
            width: 100%;
            font-size: 0.32rem;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 0.38rem;
          }
        }
      }
    }
  }
}
</style>
